/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "custom/Navbar/";
import DefaultFooter from "custom/Footer/";

// Presentation page sections
import Information from "./sections/Information";
import Proficiency from "./sections/Proficiency";
import CustomTimeline from "./sections/Experience"
import Projects from "./sections/Projects";
import Testimonials from "./sections/Education";
import Contact from "./sections/Contact";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/mandelbrot-set.png";
import meImage from "assets/images/me.png";

//
//
//
function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        height={650}
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}>
        <Container>
          <Grid container
            item xs={12}
            mt={-10}
            lg={7}
            justifyContent="center"
            mx="auto">
            <MKTypography
              variant="h4"
              color="info"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              sx={{ typography: { sm: 'h3', xs: 'h5' } }}
              mt={1}>
              Hi, I&apos;m
            </MKTypography>
            <MKTypography
              variant="h1"
              textAlign="center"
              mt={1}
              mb={1}
              style={{ color: "#8C8C8C" }}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}>
              Jean Carlo Hernández Arguedas
            </MKTypography>
            <MKTypography
              variant="h5"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 4 }}
              sx={{ typography: { sm: 'body1', xs: 'body2' } }}
              mt={1}>
              I&apos;m a mobile developer, both for native and hybrid applications. I have knowledge
              in front-end development. I enjoy teamwork, sharing ideas and knowledge. I like
              challenges, learning, and creating new things.
            </MKTypography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={7}
            justifyContent="center"
            mx="auto">
            <MKBox
              sx={{ zIndex: 8 }}
              position="absolute">
              <MKBox
                shadow="lg"
                borderRadius="50%"
                my={8}
                component="img"
                src={meImage}
                alt={meImage}
                width={230} />
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Information />
        <Proficiency />
        <CustomTimeline />
        <Testimonials />
        <Projects />
      </Card >
      <Contact />
      <MKBox pt={2} px={1} mt={4}>
        <DefaultFooter />
      </MKBox>
    </>
  );
}

export default Presentation;
