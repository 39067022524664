/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "custom/Cards/RotatingCard";
import RotatingCardFront from "custom/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "custom/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "custom/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/laptop.jpeg";
import bgBack from "assets/images/space.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                color={"black"}
                title={
                  <>
                    What I
                    <br />
                    Do?
                  </>
                }
                description="Development of mobile apps with native and hybrid technologies, create websites and some cloud process."
              />
              <RotatingCardBack
                image={bgBack}
                color={"black"}
                title="More about me"
                description="You can download my resume here."
                action={{
                  type: "external",
                  label: "Resume",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="smartphone"
                  title="Mobile Native Developer"
                  description="Experienced in the development and maintenance of applications in both native technologies (Android &amp; iOS), as well as providing maintenance for them on their respective stores."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="mergeType"
                  title="Mobile Hybrid Developer"
                  description="Experience in the development and maintenance of apps in hybrid environments using frameworks like Flutter and React Native, with responsive designs tailored to any device."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="tab"
                  title="Front-end Developer"
                  description="I also have experience in web development using frameworks like Node and Angular, as well as publishing and maintaining websites."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="cloud"
                  title="Others"
                  description="Finally, I have some experience in backend development, database management, services development, and some knowdledge about AWS or Azure platforms."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
