export default [
    {
        "index": 1,
        "place": "SOIN Soluciones Integrales S.A.",
        "position": "Mobile Staff Developer Mid",
        "description": "Support and maintenance apps: Tapp, Win App LA, Colono App. Development of vaccination code scanning app, Ministerio of Salud de Costa Rica. Deevelopment of TramiteYa app, Dirección General de Migración y Extranjería of Costa Rica.",
        "time": "August 2020 - Present",
        "skills": "iOS, Swift, Objective-C, Android, Kotlin, Java, Flutter, Git, Github",
        "type": "mobile"
    },
    {
        "index": 2,
        "place": "Synapp C.R.",
        "position": "Android Developer",
        "description": "Development and support of Android applications: Remusa, Remusa Agentes.",
        "time": "November, 2018 - June, 2020",
        "skills": "Android, Java, Git, Github",
        "type": "mobile"
    },
    {
        "index": 3,
        "place": "Efika Solutions S.A.",
        "position": "Front-end Developer",
        "description": "Development and support of applications and websites: El Observador CR web site, Emptor App.",
        "time": "February, 2019 - May, 2019",
        "skills": "Node, ReactJS, React Native, AWS, Serverless, Git, Github",
        "type": "web"
    },
    {
        "index": 4,
        "place": "Coopebaires R.L.",
        "position": "Front-end Developer",
        "description": "Development and support web sites: Coopebaires R.L web site.",
        "time": "April, 2018 - August, 2018",
        "skills": "Node, Angular",
        "type": "web"
    }
]