// import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// @mui/lab Timeline components
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

// @mui cons
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TabIcon from '@mui/icons-material/Tab';

// Datasource
import dataSource from "pages/sections/data/experience_data";

//
//
//
function Experience() {
  return (
    <MKBox py={10}>
      <Container >
        <Grid
          container
          item
          mb={10}
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}>
          <MKTypography variant="h2">Experience</MKTypography>
        </Grid>
        <Timeline position="alternate">
          {dataSource.map(({ index, place, position, description, time, skills, type }) => <TimelineItem key={index}>
            <TimelineOppositeContent
              sx={{ m: 'auto 0', typography: { sm: 'body2', xs: 'caption' } }}
              align="right"
              variant="body2"
              color="text.primary">
              {time}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color={"info"} variant="outlined">
                {type === "mobile" ? <SmartphoneIcon /> : <TabIcon />}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <MKTypography style={{ color: "black" }} component="span" sx={{ typography: { sm: 'h5', xs: 'h6' } }}>
                {place}
              </MKTypography>
              <MKTypography variant="subtitle2" sx={{ typography: { sm: 'subtitle2', xs: 'caption' } }}>
                {position}
              </MKTypography>
              <br />
              <MKTypography variant="body2" color={"black"} sx={{ typography: { sm: 'body2', xs: 'caption' } }}>
                {description}
              </MKTypography>
              <MKTypography variant="caption" color="info" fontWeight="regular">
                {skills}
              </MKTypography>
            </TimelineContent>
          </TimelineItem>)}
        </Timeline>
      </Container>
    </MKBox>
  );
}

export default Experience;