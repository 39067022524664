export default [
    {
        "index": 1,
        "color": "grey-100",
        "name": "SwiftGitApp",
        "description": "Swift app with biometric auth, shows a full list of private user github repos.",
        "tags": "iOS, Swift, SwiftUI",
        "route": "https://github.com/hajc1294/SwiftGitApp",
        "action": "Github",
        "image": "smartphone"
    },
    {
        "index": 2,
        "color": "grey-100",
        "name": "FlutterOpenAI",
        "description": "OpenAI implementation, contains a chat funcionality with CahtGPT and an image generator.",
        "tags": "Flutter, Dart, OpenAI",
        "route": "https://github.com/hajc1294/FlutterOpenAI",
        "action": "Github",
        "image": "smartphone"
    },
    {
        "index": 3,
        "color": "grey-100",
        "name": "MusicStreaming",
        "description": "Music player (streaming) with Firebase as the server.",
        "tags": "Swift, Firebase",
        "route": "https://github.com/hajc1294/MusicStreaming",
        "action": "Github",
        "image": "smartphone"
    },
    {
        "index": 4,
        "color": "grey-100",
        "name": "KotlinMapsApp",
        "description": "Google Maps application built in Kotlin.",
        "tags": "Android, Kotlin",
        "route": "https://github.com/hajc1294/KotlinMapsApp",
        "action": "Github",
        "image": "smartphone"
    },
    {
        "index": 5,
        "color": "grey-100",
        "name": "CustomCurrencyTextField",
        "description": "Objective-C pod that helps to use single Text Field with currency format.",
        "tags": "Objective-C, CocoaPods",
        "route": "https://cocoapods.org/pods/CustomCurrencyTextField",
        "action": "CocoaPods",
        "image": "smartphone"
    },
    // {
    //     "index": 4,
    //     "color": "info",
    //     "name": "Finca Jose Angel",
    //     "description": "Website developed for a tourist place, it is an informative site.",
    //     "tags": "Node, ReactJS.",
    //     "route": "https://fincajoseangel-b9a43.firebaseapp.com",
    //     "action": "Visit",
    //     "image": "tab"
    // },
]

