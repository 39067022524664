/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Images
import crImage from "assets/images/costa-rica-flag.jpg";

function Footer() {

  // Modal.
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  return (
    <MKBox component="footer">
      <Container>
        <Grid container>
          <Grid item xs={12} lg={4} mr="auto" mb={{ xs: 3, lg: 0 }}
            textAlign={{ xs: "center", lg: "left" }}>
            <Stack direction="row" flexWrap="wrap" spacing={1} mb={2} justifyContent={{ xs: "center", lg: "flex-start" }} alignItems="center">
              <MKTypography variant="h6" textTransform="uppercase" mb={{ xs: 2, lg: 3 }}>
                Coding from
              </MKTypography>
              <MKBox
                component="img"
                src={crImage}
                alt={crImage}
                height={17} />
            </Stack>
            <Stack direction={"column"} spacing={1} mb={4}>
              <MKBox>
                <MKTypography variant="button" opacity={0.8} onClick={toggleModal} sx={{ cursor: "pointer", userSelect: "none" }}>
                  About this website
                </MKTypography>
              </MKBox>
              <MKTypography variant="button" opacity={0.8}>
                Copyright © <script>document.write(new Date().getFullYear())</script>2023 Jean Carlo Hernández
              </MKTypography>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6} ml="auto" textAlign={{ xs: "center", lg: "right" }}>
            <br />
            <MKTypography variant="body1" fontWeight="bold" sx={{ fontSize: "1.125rem" }}>
              <q>Those who can imagine anything, can create the impossible.</q>
            </MKTypography>
            <MKTypography variant="body2" fontWeight="bold" mb={4} sx={{ fontSize: "1.125rem" }}>
              - Allan Turing
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={500}>
          <MKBox position="relative" width="380px" display="flex" flexDirection="column" borderRadius="xl"
            bgColor="white" shadow="xl" style={{ outline: 'none' }}>
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">About this page</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKTypography variant="body2" color="black" mb={2} fontWeight="regular">
                Credits to the sources that helped to create this webpage.
              </MKTypography>
              <MKTypography variant="body2" color="black" mb={1} fontWeight="regular">
                This page was based on a Creative Tim template, you can visit it clicking <a target="_blank"
                  rel="noreferrer" href="https://www.creative-tim.com/product/material-kit-react">here</a>.
              </MKTypography>
              <MKTypography variant="body2" color="black" fontWeight="regular">
                Some resources (images) were taken from the FreePik website, which should be credited. These are the following:
              </MKTypography>
              <MKTypography variant="body2" color="black" fontWeight="regular">
                - Image from Education section comes from <a target="_blank" rel="noreferrer"
                  href="https://www.freepik.es/foto-gratis/experiencia-programacion-persona-que-trabaja-codigos-computadora_38669451.htm#query=codigo%20javascript&position=7&from_view=keyword&track=ais">here</a>.
              </MKTypography>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox textAlign="center" display="block" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="info" onClick={toggleModal}>
                Close
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>
    </MKBox >
  );
}

export default Footer;
