// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import FilledInfoCard from "custom/Cards/InfoCards/FilledInfoCard";

// Datasource
import dataSource from "pages/sections/data/projects_data";

function Projects() {
	return (
		<MKBox py={10}>
			<Container>
				<Grid
					container
					item
					xs={12}
					lg={6}
					justifyContent="center"
					sx={{ mx: "auto", textAlign: "center" }}>
					<MKTypography variant="h2">Projects</MKTypography>
				</Grid>
				<Grid container spacing={3} py={15}>
					{dataSource.map(({ index, color, name, description, tags, route, action, image }) => <Grid key={index} item xs={12} lg={4}>
						<FilledInfoCard
							color={color}
							icon={image}
							title={name}
							description={description}
							tags={tags}
							action={{
								type: "external",
								route: route,
								label: action,
							}}
						/>
					</Grid>)}
				</Grid>
			</Container >
		</MKBox>
	);
}

export default Projects;