/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/contact.jpeg";

function Contact() {

	// Modal.
	const [show, setShow] = useState(false);
	const toggleModal = () => setShow(!show);

	// Form validations.
	const [name, setName] = useState("");
	const [nameError, setNameError] = useState(null);
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(null);
	const [message, setMessage] = useState("");
	const [messageError, setMessageError] = useState(null);

	const isValidName = () => name.length > 9;
	const isValidEmail = () => /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email);
	const isValidMessage = () => message.length > 19;

	const onChangeName = (event) => {
		setName(event.target.value);
		if (nameError !== null) {
			setNameError(null);
		}
	}

	const onChangeEmail = (event) => {
		setEmail(event.target.value);
		if (emailError !== null) {
			setEmailError(null);
		}
	}

	const onChangeMessage = (event) => {
		setMessage(event.target.value);
		if (messageError !== null) {
			setMessageError(null);
		}
	}

	const validateForm = () => {
		if (!isValidName()) {
			setNameError("Name is required with at least 10 characters.");
		}
		if (!isValidEmail()) {
			setEmailError("Email is empty or invalid.");
		}
		if (!isValidMessage()) {
			setMessageError("Message is required with at least 20 characters.");
		}
	}

	// Send email.
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();
		if (nameError === null && emailError === null && messageError === null) {
			emailjs.sendForm("service_xe3lazk", "template_zr0zgpm", form.current, "fYby0BKdgwYnwbP5O")
				.then(() => {
					setName("");
					setEmail("");
					setMessage("");
					toggleModal();
				}, (error) => {
					console.log(error.text);
				});
		}
		e.target.reset()
	};

	//
	return (
		<MKBox component="section" py={{ xs: 0, lg: 6 }}>
			<Container>
				<Grid container item>
					<MKBox
						width="100%"
						bgColor="white"
						borderRadius="xl"
						shadow="xl"
						mb={6}
						sx={{ overflow: "hidden" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} lg={5} position="relative" px={0}
								sx={{
									backgroundImage: ({
										palette: { gradients },
										functions: { rgba, linearGradient },
									}) =>
										`${linearGradient(
											rgba(gradients.black.main, 0.8),
											rgba(gradients.black.main, 0.8)
										)}, url(${bgImage})`,
									backgroundSize: "cover",
								}}>
								<MKBox display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
									<MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
										<MKTypography variant="h3" color="white" mb={1}>
											Contact Information
										</MKTypography>
										<MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
											Here is some aditional information to contact me, I will answer you as soon as possible.
										</MKTypography>
										<MKBox display="flex" p={1}>
											<MKTypography variant="button" color="info">
												<i className="fas fa-phone" />
											</MKTypography>
											<MKTypography
												component="span"
												variant="button"
												color="white"
												opacity={0.8}
												ml={2}
												fontWeight="regular">
												(+506) 8520 5724
											</MKTypography>
										</MKBox>
										<MKBox display="flex" color="white" p={1}>
											<MKTypography variant="button" color="info">
												<i className="fas fa-envelope" />
											</MKTypography>
											<MKTypography
												component="span"
												variant="button"
												color="white"
												opacity={0.8}
												ml={2}
												fontWeight="regular">
												hajc1294@gmail.com
											</MKTypography>
										</MKBox>
										<MKBox display="flex" color="white" p={1}>
											<MKTypography variant="button" color="info">
												<i className="fas fa-map-marker-alt" />
											</MKTypography>
											<MKTypography
												component="span"
												variant="button"
												color="white"
												opacity={0.8}
												ml={2}
												fontWeight="regular">
												Costa Rica, Cartago, La Unión
											</MKTypography>
										</MKBox>
									</MKBox>
								</MKBox>
							</Grid>
							<Grid item xs={12} lg={7}>
								<MKBox component="form" p={2} method="post" ref={form} onSubmit={sendEmail}>
									<MKBox px={3} py={{ xs: 2, sm: 6 }}>
										<MKTypography variant="h2" mb={1}>
											Thank for your visit!
										</MKTypography>
										<MKTypography variant="body1" color="black" mb={2}>
											Feel free to write any question or something that you want to know.
										</MKTypography>
									</MKBox>
									<MKBox pt={0.5} pb={3} px={3}>
										<Grid container>
											<Grid item xs={12} pr={1} mb={6}>
												<MKInput
													id="name"
													name="user_name"
													variant="standard"
													label="My name is"
													placeholder="Full Name"
													value={name}
													onChange={onChangeName}
													error={nameError !== null}
													InputLabelProps={{ shrink: true }}
													fullWidth
												/>
												{nameError && <MKTypography color={"error"} variant="caption">
													{nameError}
												</MKTypography>}
											</Grid>
											<Grid item xs={12} pr={1} mb={6}>
												<MKInput
													name="user_email"
													variant="standard"
													label="My email is"
													placeholder="example@mail.com"
													type="email"
													value={email}
													onChange={onChangeEmail}
													error={emailError !== null}
													InputLabelProps={{ shrink: true }}
													fullWidth
												/>
												{emailError && <MKTypography color={"error"} variant="caption">
													{emailError}
												</MKTypography>}
											</Grid>
											<Grid item xs={12} pr={1} mb={6}>
												<MKInput
													name="message"
													variant="standard"
													label="I'm looking for"
													placeholder="I want to say that..."
													value={message}
													onChange={onChangeMessage}
													error={messageError !== null}
													InputLabelProps={{ shrink: true }}
													fullWidth
													multiline
													rows={6}
												/>
												{messageError && <MKTypography color={"error"} variant="caption">
													{messageError}
												</MKTypography>}
											</Grid>
										</Grid>
										<Grid
											container
											item
											xs={12}
											md={6}
											justifyContent="flex-end"
											textAlign="right"
											ml="auto">
											<MKButton type="submit" variant="gradient" color="info" onClick={validateForm}>
												Send Message
											</MKButton>
										</Grid>
									</MKBox>
								</MKBox>
							</Grid>
						</Grid>
					</MKBox>
				</Grid>
				<Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
					<Slide direction="down" in={show} timeout={500}>
						<MKBox position="relative" width="380px" display="flex" flexDirection="column" borderRadius="xl"
							bgColor="white" shadow="xl" style={{ outline: 'none' }}>
							<MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
								<MKTypography variant="h5">Sent</MKTypography>
								<CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
							</MKBox>
							<Divider sx={{ my: 0 }} />
							<MKBox p={2}>
								<MKTypography variant="body2" color="black" fontWeight="regular">
									Your message has been sent successfully, I will contact you soon.
								</MKTypography>
							</MKBox>
							<Divider sx={{ my: 0 }} />
							<MKBox textAlign="center" display="block" justifyContent="space-between" p={1.5}>
								<MKButton variant="gradient" color="info" onClick={toggleModal}>
									Close
								</MKButton>
							</MKBox>
						</MKBox>
					</Slide>
				</Modal>
			</Container>
		</MKBox>
	);
}

export default Contact;
