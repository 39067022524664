/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "custom/Cards/ReviewCards/DefaultReviewCard";

// Images
import image from "assets/images/programming-experience.jpg";

// Datasource
import dataSource from "pages/sections/data/education_data";

//
//
//
function Education() {
  return (
    <MKBox py={10}>
      <MKBox
        component="section"
        py={10}
        bgColor={"darkBackground"}
        borderRadius="10px"
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: "fixed",
        }}
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.black ? gradients.black.main : gradients.info.main, 0.75),
              rgba(gradients.black ? gradients.black.main : gradients.info.main, 0.75)
            )}, url(${image})`,
          backgroundSize: "cover",
          backfaceVisibility: "hidden",
        }}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}>
            <MKTypography variant="h2" color={"white"}>
              Education
            </MKTypography>

          </Grid>
          <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{ mt: 8 }}>
            {dataSource.map(({ index, institution, degree, area, time, color }) =>
              <Grid key={index} item xs={12} md={6} lg={4}>
                <DefaultReviewCard
                  color={color}
                  name={institution}
                  date={time}
                  degree={degree}
                  area={area} />
              </Grid>)}
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Education;
