/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";

const routes = [
  {
    name: "Telegram",
    icon: <TelegramIcon />,
    href: "https://t.me/hajc1294",
  },
  {
    name: "LinkedIn",
    icon: <LinkedInIcon />,
    href: "https://www.linkedin.com/in/jean-carlo-hernandez-arguedas-752b021b3/",
  },
  {
    name: "github",
    icon: <GitHubIcon />,
    href: "https://github.com/hajc1294",
  },
];

export default routes;
