// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKProgress from "components/MKProgress";

// React componets
import Lottie from "lottie-react";

// Images
import animation from "assets/animations/progress.json"

function Proficiency() {
  return (
    <MKBox py={10}>
      <MKBox component="section" py={{ xs: 3, md: 12 }} bgColor={"darkBackground"} borderRadius="10px">
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={12} lg={5}>
              <MKTypography variant="h3" my={1}>
                Skills
              </MKTypography>
              <MKBox py={5}>
                <MKBox mb={3}>
                  <Grid container
                    direction="row"
                    justifyContent="start"
                    alignItems="start" mb={2}>
                    <Grid item xs={11}>
                      <MKTypography variant="body2">Mobile</MKTypography>
                    </Grid>
                    <Grid item xs={1}>
                      <MKTypography variant="body2">80%</MKTypography>
                    </Grid>
                  </Grid>
                  <MKProgress color="info" value={80} />
                </MKBox>
                <MKBox mb={3}>
                  <Grid container
                    direction="row"
                    justifyContent="start"
                    alignItems="start" mb={2}>
                    <Grid item xs={11}>
                      <MKTypography variant="body2">Front-end/Web</MKTypography>
                    </Grid>
                    <Grid item xs={1}>
                      <MKTypography variant="body2">50%</MKTypography>
                    </Grid>
                  </Grid>
                  <MKProgress color="info" value={50} />
                </MKBox>
                <MKBox mb={3}>
                  <Grid container
                    direction="row"
                    justifyContent="start"
                    alignItems="start" mb={2}>
                    <Grid item xs={11}>
                      <MKTypography variant="body2">Back-end/Devops</MKTypography>
                    </Grid>
                    <Grid item xs={1}>
                      <MKTypography variant="body2">30%</MKTypography>
                    </Grid>
                  </Grid>
                  <MKProgress color="info" value={30} />
                </MKBox>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={5} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
              <MKBox width={350}>
                <Lottie animationData={animation} loop={true} />
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox >
    </MKBox >
  );
}

export default Proficiency;